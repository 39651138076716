body {
	background: radial-gradient(circle at center, hsla(0, 0%, 100%, .95) 0,
		rgba(119, 186, 210, 0) 60%), #aed2ec;
}
.App-header {
    z-index: 1000;
    background-color: #fff;
    box-shadow: 0 5px 3px -2px rgba(0, 0, 0, 0.3);
    padding: 10px;
	position: fixed;
	top: 0;
	width: 100%;
 }

  .logo-img {
    max-height: 60px;
  }

  .about-us {
    background-color: #eee;
    margin-left: -7px;
    margin-right: -7px
  }
  .about-us-header {
    color: '#004f9c';
    font-weight: bold;
  }

  .footer-seal {
    background-color: rgba(247,247,247,1);
    padding: 15px 0;
    margin: -2px -2px 0 -2px;
    text-align: center;     
  }
  .footer-text {
    padding: 20px;
    font-family: 'Source Sans Pro';
    font-size: 14px;
    text-align: center;
    margin-top: 8px;
    color: white;
  }

  .footer1 {
	display: none;
    background: #1d2755;
}

.footer2 {
	display: block;
	background-color: #1d2755;
	font-family: Source Sans Pro;
	font-size: 16px;
	color: #aed2ec;
	border: 1px solid #1d2755;
	font-family: Source Sans Pro;
	font-size: 16px;
	color: #aed2ec;
}

.footer2 a {
	color: #aed2ec;
	font-family: Source Sans Pro;
	font-size: 16px;
}

.footer2-text {
	font-family: 'Source Sans Pro';
	font-size: 12px;
	text-align: justify;
	color: white;
	background-color: #1d2755;
	padding: 10px 20px 10px 20px;
}

.footer2-text a {
	font-family: 'Source Sans Pro';
	font-size: 12px;
	color: #aed2ec;
}

.main-body {
	width: 100%;
	font-size: 16px;
	padding-top: 90px;
}

.img-girl {
	display: none;
}

.process-title {
	padding: 30px 0 20px 0;
}

@media ( min-width : 768px) and (max-width: 1000px) {
	.container {
		width: 100%
	}
}

@media only screen and (min-width: 1000px) and
	(-webkit-min-device-pixel-ratio: 1) {
	.process-title {
		padding: 70px 0 30px 0;
	}
	.footer1 {
		display: block;
	}
	.footer2 {
		display: none;
	}
	.main-body {
		background: radial-gradient(circle at center, hsla(0, 0%, 100%, .95) 0,
			rgba(119, 186, 210, 0) 60%), #aed2ec;
		position: relative;
		height: 430px;
		font-size: 16px;
	}
	.sticky {
		position: fixed;
		top: 0;
		width: 100%
	}
	.img-girl {
		display: block;
	}
	.img-girl img {
		width: 300px;
		height: 300px;
	}
}

.footer-menu {
	color: #aed2ec;
	text-align: center;
	padding-top: 30px;
}

.footer-menu a {
	color: #aed2ec;
	padding-left: 20px;
}

.ola {
	left: 15px;
	top: -225px;
	z-index: 2;
	position: relative;
}

.ola-banner {
	z-index: 1;
	position: inherit;
	left: 0;
}

.footer-link {
	color: #aed2ec;
	text-wrap: nowrap;
}

.footer-link:hover {
	color: #aed2ec;
}

.disclaimer-text {
    margin-left: -160px;
    padding: 40px 20px 60px 0;
    color: #fff;
    text-align: justify;
}
.footer1-text {
    padding: 40px 0 0 0;
    font-family: 'Source Sans Pro';
    font-size: 14px;
    text-align: center;
    margin-top: 8px; color: white;    
}
.unsubscribe-panel {
	min-height: 400px;
}